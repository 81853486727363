import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const overlayStyle = {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.3)',
  transition: '.3s ease-out',
  opacity: 0,
};

const Overlay = ({ children = null }) => {
  return (
    <Box sx={overlayStyle} className='overlay-on-hover'>
      {children}
    </Box>
  );
};

Overlay.propTypes = {
  children: PropTypes.node,
};

export default Overlay;
