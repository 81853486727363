'use client';
import { useRef } from 'react';
import { useInView } from 'framer-motion';
import { Box, Paper, Typography, Chip, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';
import { asText } from '@prismicio/client';
import CustomPrismicText from './customPrismicText';
import Overlay from './overlay';
import Date from '../../components/date';

const styles = {
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textDecoration: 'none',
    textAlign: 'left',
    borderRadius: '9px',
    '& img': {
      transition: 'all 0.3s',
    },
    ':hover, :active': {
      transform: 'translateY(-10px)',
      backgroundColor: '#fafafa',
      '& .overlay-on-hover': {
        opacity: 1,
      },
      '& img': {
        transform: 'scale(1.1)',
      },
    },
  },
  imgBlock: {
    position: 'relative',
    height: { xs: '180px', md: '230px' },
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: '9px',
    borderTopRightRadius: '9px',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  imgSkeleton: {
    width: '100%',
    height: '100%',
  },
  itemContent: {
    flexGrow: 1,
    padding: { xs: '24px 12px', sm: '24px' },
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: '8px', md: '15px' },
  },

  itemTitle: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  itemDescription: {
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  dateBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
    borderBottom: '1px solid #eee',
    borderTop: '1px solid #eee',
  },
  date: {
    color: '#5D5D5D',
  },
  chips: {
    display: 'flex',
    gap: '5px',
  },
};

const ArticleBlock = ({
  item = null,
  shouldHideDate = false,
  transitionDelay = '0ms',
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  if (!item)
    return (
      <Paper sx={styles.itemContainer}>
        <Box sx={styles.imgBlock}>
          <Skeleton
            animation='wave'
            variant='rectangular'
            sx={styles.imgSkeleton}
          />
        </Box>

        <Box sx={styles.itemContent}>
          <Box>
            <Typography variant='h6'>
              <Skeleton animation='wave' variant='rounded' />
            </Typography>
          </Box>
          <Box sx={styles.dateBlock}>
            <Skeleton
              animation='wave'
              variant='rounded'
              sx={{ height: '24px', width: '100%' }}
            />
          </Box>

          <Box>
            <Typography variant='body1'>
              <Skeleton animation='wave' />
            </Typography>
            <Typography variant='body1'>
              <Skeleton animation='wave' />
            </Typography>
          </Box>
        </Box>
      </Paper>
    );

  const {
    data,
    last_publication_date: lastPublicationDate,
    first_publication_date: firstPublicationDate,
  } = item;

  return (
    <Paper
      ref={ref}
      component={PrismicNextLink}
      field={item}
      sx={{
        ...styles.itemContainer,
        opacity: isInView ? 1 : 0,
        transitionProperty: 'opacity, transform',
        transitionDuration: '1s, 0.3s',
        transitionTimingFunction: 'ease-in-out',
        transitionDelay: `${transitionDelay}, 0ms`,
      }}>
      <Box sx={styles.imgBlock}>
        <Overlay />
        <PrismicNextImage field={data.image} />
      </Box>

      <Box sx={styles.itemContent}>
        <Box>
          <CustomPrismicText
            sx={styles.itemTitle}
            field={data.title}
            variant='h6'
          />
        </Box>

        {!shouldHideDate && (
          <Box sx={styles.dateBlock}>
            <Typography variant='body2' sx={styles.date}>
              <Date dateString={firstPublicationDate} />
            </Typography>
            <Box sx={styles.chips}>
              {data.categories?.map(({ category }) => {
                return (
                  <Chip
                    key={category.id}
                    label={asText(category.data.name)}
                    variant='outlined'
                  />
                );
              })}
            </Box>
          </Box>
        )}
        <Box>
          <CustomPrismicText
            sx={styles.itemDescription}
            field={data.description}
          />
        </Box>
      </Box>
    </Paper>
  );
};

ArticleBlock.propTypes = {
  item: PropTypes.object,
  shouldHideDate: PropTypes.bool,
};

export default ArticleBlock;
