import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CustomPrismicText from '../../components/prismic/customPrismicText';
import ArticleBlock from '../../components/prismic/articleBlock';
import { isFilled } from '@prismicio/client';

/**
 * @typedef {import("@prismicio/client").Content.ReadMoreSliceSlice} ReadMoreSliceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ReadMoreSliceSlice>} ReadMoreSliceProps
 * @param {ReadMoreSliceProps}
 */

const styles = {
  section: {
    padding: { xs: '48px 18px', md: '100px 96px' },
  },
  container: {
    maxWidth: '1248px',
    margin: '0 auto',
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Helvetica Neue, sans-serif',
    fontSize: '30px',
    fontWeight: 500,
    lineHeight: '45px',
    letterSpacing: '0.02em',
    marginBottom: { xs: '12px', md: '24px' },
  },
  itemsList: {
    marginTop: '40px',
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
    gap: { xs: '12px', md: '24px' },
  },
};

const ReadMoreSlice = ({ slice }) => {
  const {
    primary: {
      bg_color: bgColor,
      background_image: bgImg,
      slice_title: sliceTitle,
      slice_description: sliceDescription,
      hide_dates: hideDates = false,
    },
    items: readMoreArticles = [],
  } = slice;
  return (
    <Box
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      component='section'
      sx={{
        ...styles.section,
        backgroundImage: isFilled.image(bgImg) ? `url(${bgImg.url})` : 'unset',
        backgroundColor: bgColor,
      }}>
      <Box sx={styles.container}>
        <CustomPrismicText
          field={sliceTitle}
          sx={styles.title}
          variant='h2'
          component='h2'
        />
        <CustomPrismicText field={sliceDescription} sx={styles.description} />
        <Box sx={styles.itemsList}>
          {readMoreArticles.map((item, index) => {
            return (
              <ArticleBlock
                key={item.linked_document.id}
                transitionDelay={`${index * 500}ms`}
                item={item.linked_document}
                shouldHideDate={hideDates}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

ReadMoreSlice.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ReadMoreSlice;
